export default [
  {
    path: "/timeline",
    name: "list-timeline",
    component: () => import("@/views/timeline/List.vue"),
    meta: {
      pageTitle: "breadcrumb.timeline",
      resource: "timeline",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listTimeline",
          active: true,
        },
      ],
    },
  },
  {
    path: "/timeline/create",
    name: "create-timeline",
    component: () => import("@/views/timeline/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.timeline",
      navActiveLink: "list-timeline",
      resource: "timeline",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listTimeline",
          to: { name: "list-timeline" },
        },
        {
          text: "breadcrumb.createTimeline",
          active: true,
        },
      ],
    },
  },
  {
    path: "/timeline/:id/update",
    name: "update-timeline",
    component: () => import("@/views/timeline/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.timeline",
      navActiveLink: "list-timeline",
      resource: "timeline",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listTimeline",
          to: { name: "list-timeline" },
        },
        {
          text: "breadcrumb.updateTimeline",
          active: true,
        },
      ],
    },
  },
];
