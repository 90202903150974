export default [
    {
      path: '/gallery',
      name: 'list-gallery',
      component: () => import('@/views/gallery/List.vue'),
      meta: {
        pageTitle: 'breadcrumb.gallery',
        resource: 'gallery',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.listgallery',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gallery/create',
      name: 'create-gallery',
      component: () => import('@/views/gallery/Create.vue'),
      meta: {
        pageTitle: 'breadcrumb.gallery',
        navActiveLink: 'list-gallery',
        resource: 'gallery',
        action: 'create',
        breadcrumb: [
          {
            text: 'breadcrumb.listgallery',
            to: { name: 'list-gallery' }
          },
          {
            text: 'breadcrumb.creategallery',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gallery/:id/update',
      name: 'update-gallery',
      component: () => import('@/views/gallery/Update.vue'),
      meta: {
        pageTitle: 'breadcrumb.gallery',
        navActiveLink: 'list-gallery',
        resource: 'gallery',
        action: 'update',
        breadcrumb: [
          {
            text: 'breadcrumb.listgallery',
            to: { name: 'list-gallery' }
          },
          {
            text: 'breadcrumb.updategallery',
            active: true,
          },
        ],
      },
    },
   
  ]
  