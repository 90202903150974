import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/gallery_category`;

export default {
  index(payload) {
    return Client.get(resource, {
      params: prepareSearchParams(payload),
    });
  },
  create(payload) {
    return Client.post(resource, payload);
  },
  show(id, payload = {}) {
    return Client.get(`${resource}/${id}`, payload);
  },
  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  destroy(id) {
    return Client.delete(`${resource}/${id}`);
  },
  options(payload) {
    return Client.get(`${resource}/option`, {
      params: payload
    });
  },
};
