export default [
    {
      path: "/lifestyle",
      name: "list-lifestyle",
      component: () => import("@/views/lifestyle/List.vue"),
      meta: {
        pageTitle: "breadcrumb.lifestyle",
        resource: "lifestyle",
        action: "read",
        breadcrumb: [
          {
            text: "breadcrumb.listLifestyle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/lifestyle/create",
      name: "create-lifestyle",
      component: () => import("@/views/lifestyle/Create.vue"),
      meta: {
        pageTitle: "breadcrumb.lifestyle",
        navActiveLink: "list-lifestyle",
        resource: "lifestyle",
        action: "create",
        breadcrumb: [
          {
            text: "breadcrumb.listLifestyle",
            to: { name: "list-lifestyle" },
          },
          {
            text: "breadcrumb.createLifestyle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/lifestyle/:id/update",
      name: "update-lifestyle",
      component: () => import("@/views/lifestyle/Update.vue"),
      meta: {
        pageTitle: "breadcrumb.lifestyle",
        navActiveLink: "list-lifestyle",
        resource: "lifestyle",
        action: "update",
        breadcrumb: [
          {
            text: "breadcrumb.listLifestyle",
            to: { name: "list-lifestyle" },
          },
          {
            text: "breadcrumb.updateLifestyle",
            active: true,
          },
        ],
      },
    },
    {
      path: "/lifestyle/:id/clone",
      name: "clone-lifestyle",
      component: () => import("@/views/lifestyle/Clone.vue"),
      meta: {
        pageTitle: "breadcrumb.lifestyle",
        navActiveLink: "list-lifestyle",
        resource: "lifestyle",
        action: "clone",
        breadcrumb: [
          {
            text: "breadcrumb.listLifestyle",
            to: { name: "list-lifestyle" },
          },
          {
            text: "breadcrumb.cloneLifestyle",
            active: true,
          },
        ],
      },
    },
  ];
  