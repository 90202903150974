export default [
    {
        path: '/directory',
        name: 'list-directory',
        component: () => import('@/views/directory/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.directory',
            resource: 'directory',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listdirectory',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/directory/create',
        name: 'create-directory',
        component: () => import('@/views/directory/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.directory',
            navActiveLink: 'list-directory',
            resource: 'directory',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listdirectory',
                    to: { name: 'list-directory' }
                },
                {
                    text: 'breadcrumb.createdirectory',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/directory/:id/update',
        name: 'update-directory',
        component: () => import('@/views/directory/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.directory',
            navActiveLink: 'list-directory',
            resource: 'directory',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listdirectory',
                    to: { name: 'list-directory' }
                },
                {
                    text: 'breadcrumb.updatedirectory',
                    active: true,
                },
            ],
        },
    },
]
