import ProfileRepository from "./Api/ProfileRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import FileRepository from "./Api/FileRepository";
import TimelineRepository from "./Api/TimelineRepository";
import ContactRepository from "./Api/ContactRepository";
import FooterRepository from "./Api/FooterRepository";
import GalleryRepository from "./Api/GalleryRepository";
import GalleryCategoryRepository from "./Api/GalleryCategoryRepository";
import TestimonyRepository from "./Api/TestimonyRepository";
import DirectoryRepository from "./Api/DirectoryRepository";
import ArticleCategoryRepository from "./Api/ArticleCategoryRepository";
import ArticleRepository from "./Api/ArticleRepository";
import CareerGalleryRepository from "./Api/CareerGalleryRepository";
import CompanyRepository from "./Api/CompanyRepository";
import DepartmentRepository from "./Api/DepartmentRepository";
import SourceRepository from "./Api/SourceRepository";
import ApplicantRepository from "./Api/ApplicantRepository";
import TenantRepository from "./Api/TenantRepository";
import JobRepository from "./Api/JobRepository";
import BrandCategoryRepository from "./Api/BrandCategoryRepository";
import BrandRepository from "./Api/BrandRepository";
import BusinessRepository from "./Api/BusinessRepository";
import ProjectRepository from "./Api/ProjectRepository";
import BannerRepository from "./Api/BannerRepository";
import PartnerRepository from "./Api/PartnerRepository";
import AwardRepository from "./Api/AwardRepository";
import LifestyleRepositry from "./Api/LifestyleRepositry";
import DirectoryPageRepository from "./Api/DirectoryPageRepository";
import ShoppingPageRepository from "./Api/ShoppingPageRepository";
import BusinessPageRepository from "./Api/BusinessPageRepository";
import AboutPageRepository from "./Api/AboutPageRepository";
import ContactPageRepository from "./Api/ContactPageRepository";
import CareerPageRepository from "./Api/CareerPageRepository";
import GalleryPageRepository from "./Api/GalleryPageRepository";
import ArticlePageRepository from "./Api/ArticlePageRepository";
import EducationLeisureRepository from "./Api/EducationLeisureRepository";
import BrandDirectoryRepository from "./Api/BrandDirectoryRepository";

const repositories = {
    'profile': ProfileRepository,
    'user': UserRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'file': FileRepository,
    'timeline': TimelineRepository,
    'contact': ContactRepository,
    'footer': FooterRepository,
    'gallery': GalleryRepository,
    'testimony': TestimonyRepository,
    'galleryCategory': GalleryCategoryRepository,
    'directory': DirectoryRepository,
    'articleCategory': ArticleCategoryRepository,
    'article': ArticleRepository,
    "directory": DirectoryRepository,
    "careerGallery": CareerGalleryRepository,
    "company": CompanyRepository,
    'department': DepartmentRepository,
    'source': SourceRepository,
    'applicant': ApplicantRepository,
    'tenant': TenantRepository,
    'job': JobRepository,
    'brandCategory': BrandCategoryRepository,
    'brand': BrandRepository,
    'business': BusinessRepository,
    'project': ProjectRepository,
    'banner': BannerRepository,
    'partner': PartnerRepository,
    'award': AwardRepository,
    'lifestyle': LifestyleRepositry,
    'directoryPage': DirectoryPageRepository,
    'shoppingPage': ShoppingPageRepository,
    'businessPage': BusinessPageRepository,
    'aboutPage': AboutPageRepository,
    'contactPage': ContactPageRepository,
    'careerPage': CareerPageRepository,
    'galleryPage': GalleryPageRepository,
    'articlePage': ArticlePageRepository,
    'educationLeisure': EducationLeisureRepository,
    'brandDirectory': BrandDirectoryRepository,
};

export default {
    get: name => repositories[name]
};
