export default [
  {
    path: "/applicant",
    name: "list-applicant",
    component: () => import("@/views/applicant/List.vue"),
    meta: {
      pageTitle: "breadcrumb.applicant",
      resource: "applicant",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listApplicant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/applicant/:id",
    name: "view-applicant",
    component: () => import("@/views/applicant/View.vue"),
    meta: {
      pageTitle: "breadcrumb.applicant",
      navActiveLink: "list-applicant",
      resource: "applicant",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listApplicant",
          to: { name: "list-applicant" },
        },
        {
          text: "breadcrumb.viewApplicant",
          active: true,
        },
      ],
    },
  },
];
