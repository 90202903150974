export default [
  {
    path: "/education-leisure",
    name: "list-education-leisure",
    component: () => import("@/views/educationLeisure/List.vue"),
    meta: {
      pageTitle: "breadcrumb.educationLeisure",
      resource: "education-leisure",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listEducationLeisure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/education-leisure/create",
    name: "create-education-leisure",
    component: () => import("@/views/educationLeisure/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.educationLeisure",
      navActiveLink: "list-education-leisure",
      resource: "education-leisure",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listEducationLeisure",
          to: { name: "list-education-leisure" },
        },
        {
          text: "breadcrumb.createEducationLeisure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/education-leisure/:id/update",
    name: "update-education-leisure",
    component: () => import("@/views/educationLeisure/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.educationLeisure",
      navActiveLink: "list-education-leisure",
      resource: "education-leisure",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listEducationLeisure",
          to: { name: "list-education-leisure" },
        },
        {
          text: "breadcrumb.updateEducationLeisure",
          active: true,
        },
      ],
    },
  },
  {
    path: "/education-leisure/:id/clone",
    name: "clone-education-leisure",
    component: () => import("@/views/educationLeisure/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.educationLeisure",
      navActiveLink: "list-education-leisure",
      resource: "education-leisure",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listEducationLeisure",
          to: { name: "list-education-leisure" },
        },
        {
          text: "breadcrumb.cloneEducationLeisure",
          active: true,
        },
      ],
    },
  },
];
