export default [
  {
    path: "/banner/update",
    name: "update-banner",
    component: () => import("@/views/banner/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.banner",
      resource: "banner",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.updateBanner",
          active: true,
        },
      ],
    },
  },
];
