export default [
  {
    path: "/project",
    name: "list-project",
    component: () => import("@/views/project/List.vue"),
    meta: {
      pageTitle: "breadcrumb.project",
      resource: "project",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listProject",
          active: true,
        },
      ],
    },
  },
  {
    path: "/project/create",
    name: "create-project",
    component: () => import("@/views/project/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.project",
      navActiveLink: "list-project",
      resource: "project",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listProject",
          to: { name: "list-project" },
        },
        {
          text: "breadcrumb.createProject",
          active: true,
        },
      ],
    },
  },
  {
    path: "/project/:id/update",
    name: "update-project",
    component: () => import("@/views/project/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.project",
      navActiveLink: "list-project",
      resource: "project",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listProject",
          to: { name: "list-project" },
        },
        {
          text: "breadcrumb.updateProject",
          active: true,
        },
      ],
    },
  },
  {
    path: "/project/:id/clone",
    name: "clone-project",
    component: () => import("@/views/project/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.project",
      navActiveLink: "list-project",
      resource: "project",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listProject",
          to: { name: "list-project" },
        },
        {
          text: "breadcrumb.cloneProject",
          active: true,
        },
      ],
    },
  },
];
