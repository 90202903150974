export default [
    {
        path: '/career-gallery',
        name: 'list-career-gallery',
        component: () => import('@/views/careerGallery/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.careerGallery',
            resource: 'career-gallery',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCareerGallery',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/career-gallery/create',
        name: 'create-career-gallery',
        component: () => import('@/views/careerGallery/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.careerGallery',
            navActiveLink: 'list-career-gallery',
            resource: 'career-gallery',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCareerGallery',
                    to: { name: 'list-career-gallery' }
                },
                {
                    text: 'breadcrumb.createCareerGallery',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/career-gallery/:id/update',
        name: 'update-career-gallery',
        component: () => import('@/views/careerGallery/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.careerGallery',
            navActiveLink: 'list-career-gallery',
            resource: 'career-gallery',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCareerGallery',
                    to: { name: 'list-career-gallery' }
                },
                {
                    text: 'breadcrumb.updateCareerGallery',
                    active: true,
                },
            ],
        },
    },
]
