export default [
  {
    path: "/source",
    name: "list-source",
    component: () => import("@/views/source/List.vue"),
    meta: {
      pageTitle: "breadcrumb.source",
      resource: "source",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listSource",
          active: true,
        },
      ],
    },
  },
  {
    path: "/source/create",
    name: "create-source",
    component: () => import("@/views/source/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.source",
      navActiveLink: "list-source",
      resource: "source",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listSource",
          to: { name: "list-source" },
        },
        {
          text: "breadcrumb.createSource",
          active: true,
        },
      ],
    },
  },
  {
    path: "/source/:id/update",
    name: "update-source",
    component: () => import("@/views/source/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.source",
      navActiveLink: "list-source",
      resource: "source",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listSource",
          to: { name: "list-source" },
        },
        {
          text: "breadcrumb.updateSource",
          active: true,
        },
      ],
    },
  },
];
