export default [
    {
      path: '/gallery-category',
      name: 'list-gallery-category',
      component: () => import('@/views/galleryCategory/List.vue'),
      meta: {
        pageTitle: 'breadcrumb.galleryCategory',
        resource: 'gallery-category',
        action: 'read',
        breadcrumb: [
          {
            text: 'breadcrumb.listGalleryCategory',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gallery-category/create',
      name: 'create-gallery-category',
      component: () => import('@/views/galleryCategory/Create.vue'),
      meta: {
        pageTitle: 'breadcrumb.galleryCategory',
        navActiveLink: 'list-gallery-category',
        resource: 'gallery-category',
        action: 'create',
        breadcrumb: [
          {
            text: 'breadcrumb.listGalleryCategory',
            to: { name: 'list-gallery-category' }
          },
          {
            text: 'breadcrumb.createGalleryCategory',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gallery-category/:id/update',
      name: 'update-gallery-category',
      component: () => import('@/views/galleryCategory/Update.vue'),
      meta: {
        pageTitle: 'breadcrumb.galleryCategory',
        navActiveLink: 'list-gallery-category',
        resource: 'gallery-category',
        action: 'update',
        breadcrumb: [
          {
            text: 'breadcrumb.listGalleryCategory',
            to: { name: 'list-gallery-category' }
          },
          {
            text: 'breadcrumb.updateGalleryCategory',
            active: true,
          },
        ],
      },
    },
  
  ]
  