export default [
  {
    path: "/tenant",
    name: "list-tenant",
    component: () => import("@/views/tenant/List.vue"),
    meta: {
      pageTitle: "breadcrumb.tenant",
      resource: "tenant",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listTenant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tenant/create",
    name: "create-tenant",
    component: () => import("@/views/tenant/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.tenant",
      navActiveLink: "list-tenant",
      resource: "tenant",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listTenant",
          to: { name: "list-tenant" },
        },
        {
          text: "breadcrumb.createTenant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tenant/:id/update",
    name: "update-tenant",
    component: () => import("@/views/tenant/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.tenant",
      navActiveLink: "list-tenant",
      resource: "tenant",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listTenant",
          to: { name: "list-tenant" },
        },
        {
          text: "breadcrumb.updateTenant",
          active: true,
        },
      ],
    },
  },
  {
    path: "/tenant/:id/clone",
    name: "clone-tenant",
    component: () => import("@/views/tenant/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.tenant",
      navActiveLink: "list-tenant",
      resource: "tenant",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listTenant",
          to: { name: "list-tenant" },
        },
        {
          text: "breadcrumb.cloneTenant",
          active: true,
        },
      ],
    },
  },
];
