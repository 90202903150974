export default [
  {
    path: "/job",
    name: "list-job",
    component: () => import("@/views/job/List.vue"),
    meta: {
      pageTitle: "breadcrumb.job",
      resource: "job",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listJob",
          active: true,
        },
      ],
    },
  },
  {
    path: "/job/create",
    name: "create-job",
    component: () => import("@/views/job/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.job",
      navActiveLink: "list-job",
      resource: "job",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listJob",
          to: { name: "list-job" },
        },
        {
          text: "breadcrumb.createJob",
          active: true,
        },
      ],
    },
  },
  {
    path: "/job/:id/update",
    name: "update-job",
    component: () => import("@/views/job/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.job",
      navActiveLink: "list-job",
      resource: "job",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listJob",
          to: { name: "list-job" },
        },
        {
          text: "breadcrumb.updateJob",
          active: true,
        },
      ],
    },
  },
  {
    path: "/job/:id/clone",
    name: "clone-job",
    component: () => import("@/views/job/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.job",
      navActiveLink: "list-job",
      resource: "job",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listJob",
          to: { name: "list-job" },
        },
        {
          text: "breadcrumb.cloneJob",
          active: true,
        },
      ],
    },
  },
];
