export default [
  {
    path: "/partner/update",
    name: "update-partner",
    component: () => import("@/views/partner/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.partner",
      resource: "partner",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.updatePartner",
          active: true,
        },
      ],
    },
  },
];
