export default [
  {
    path: "/contact",
    name: "list-contact",
    component: () => import("@/views/contact/List.vue"),
    meta: {
      pageTitle: "breadcrumb.contact",
      resource: "contact",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listContact",
          active: true,
        },
      ],
    },
  },
  {
    path: "/contact/:id",
    name: "view-contact",
    component: () => import("@/views/contact/View.vue"),
    meta: {
      pageTitle: "breadcrumb.contact",
      navActiveLink: "list-contact",
      resource: "contact",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listContact",
          to: { name: "list-contact" },
        },
        {
          text: "breadcrumb.viewContact",
          active: true,
        },
      ],
    },
  },
];
