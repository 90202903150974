export default [
    {
      path: "/page-content",
      name: "page-content",
      component: () => import("@/views/pageContent/PageContent.vue"),
      meta: {
        pageTitle: "breadcrumb.pageContent",
        resource: "page-content",
        action: "read",
        breadcrumb: [
          {
            text: "breadcrumb.pageContent",
            active: true,
          },
        ],
      },
    },
  ];
  