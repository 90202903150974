export default [
  {
    path: "/brand",
    name: "list-brand",
    component: () => import("@/views/brand/List.vue"),
    meta: {
      pageTitle: "breadcrumb.brand",
      resource: "brand",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listBrand",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand/create",
    name: "create-brand",
    component: () => import("@/views/brand/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.brand",
      navActiveLink: "list-brand",
      resource: "brand",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listBrand",
          to: { name: "list-brand" },
        },
        {
          text: "breadcrumb.createBrand",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand/:id/update",
    name: "update-brand",
    component: () => import("@/views/brand/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.brand",
      navActiveLink: "list-brand",
      resource: "brand",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listBrand",
          to: { name: "list-brand" },
        },
        {
          text: "breadcrumb.updateBrand",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand/:id/clone",
    name: "clone-brand",
    component: () => import("@/views/brand/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.brand",
      navActiveLink: "list-brand",
      resource: "brand",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listBrand",
          to: { name: "list-brand" },
        },
        {
          text: "breadcrumb.cloneBrand",
          active: true,
        },
      ],
    },
  },
];
