import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";
import { prepareSearchParams } from "@/libs/helper";

const resource = `${Config.basePath}/v1/admin/contact_page`;

export default {

  show(payload) {
    return Client.get(resource, {
      params: prepareSearchParams(payload),
    });
  },
  
  create(payload) {
    return Client.post(resource, payload);
  },

  update(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },

};
