export default [
  {
    path: "/article",
    name: "list-article",
    component: () => import("@/views/article/List.vue"),
    meta: {
      pageTitle: "breadcrumb.article",
      resource: "article",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listArticle",
          active: true,
        },
      ],
    },
  },
  {
    path: "/article/create",
    name: "create-article",
    component: () => import("@/views/article/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.article",
      navActiveLink: "list-article",
      resource: "article",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listArticle",
          to: { name: "list-article" },
        },
        {
          text: "breadcrumb.createArticle",
          active: true,
        },
      ],
    },
  },
  {
    path: "/article/:id/update",
    name: "update-article",
    component: () => import("@/views/article/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.article",
      navActiveLink: "list-article",
      resource: "article",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listArticle",
          to: { name: "list-article" },
        },
        {
          text: "breadcrumb.updateArticle",
          active: true,
        },
      ],
    },
  },
  {
    path: "/article/:id/clone",
    name: "clone-article",
    component: () => import("@/views/article/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.article",
      navActiveLink: "list-article",
      resource: "article",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listArticle",
          to: { name: "list-article" },
        },
        {
          text: "breadcrumb.cloneArticle",
          active: true,
        },
      ],
    },
  },
];
