import Client from "@/repositories/Clients/AxiosClient";
import Config from "@/repositories/Clients/AxiosDefaultConfig";

const resource = `${Config.basePath}/v1/admin/footer`;

export default {
  show(payload = {}) {
    return Client.get(resource, payload);
  },
  update(payload) {
    return Client.put(resource, payload);
  },
};
