export default [
  {
    path: "/testimony",
    name: "list-testimony",
    component: () => import("@/views/testimony/List.vue"),
    meta: {
      pageTitle: "breadcrumb.testimony",
      resource: "testimony",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listTestimony",
          active: true,
        },
      ],
    },
  },
  {
    path: "/testimony/create",
    name: "create-testimony",
    component: () => import("@/views/testimony/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.testimony",
      navActiveLink: "list-testimony",
      resource: "testimony",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listTestimony",
          to: { name: "list-testimony" },
        },
        {
          text: "breadcrumb.createTestimony",
          active: true,
        },
      ],
    },
  },
  {
    path: "/testimony/:id/update",
    name: "update-testimony",
    component: () => import("@/views/testimony/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.testimony",
      navActiveLink: "list-testimony",
      resource: "testimony",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listTestimony",
          to: { name: "list-testimony" },
        },
        {
          text: "breadcrumb.updateTestimony",
          active: true,
        },
      ],
    },
  },
];
