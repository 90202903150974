export default [
  {
    path: '/footer/update',
    name: 'update-footer',
    component: () => import('@/views/footer/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.footer',
      resource: 'footer',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.updateFooter',
          active: true,
        },
      ],
    },
  },
]
