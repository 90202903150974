export default [
  {
    path: "/brand-category",
    name: "list-brand-category",
    component: () => import("@/views/brandCategory/List.vue"),
    meta: {
      pageTitle: "breadcrumb.brandCategory",
      resource: "brand-category",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listBrandCategory",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand-category/create",
    name: "create-brand-category",
    component: () => import("@/views/brandCategory/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.brandCategory",
      navActiveLink: "list-brand-category",
      resource: "brand-category",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listBrandCategory",
          to: { name: "list-brand-category" },
        },
        {
          text: "breadcrumb.createBrandCategory",
          active: true,
        },
      ],
    },
  },
  {
    path: "/brand-category/:id/update",
    name: "update-brand-category",
    component: () => import("@/views/brandCategory/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.brandCategory",
      navActiveLink: "list-brand-category",
      resource: "brand-category",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listBrandCategory",
          to: { name: "list-brand-category" },
        },
        {
          text: "breadcrumb.updateBrandCategory",
          active: true,
        },
      ],
    },
  },
];
