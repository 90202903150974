export default [
  {
    path: "/business",
    name: "list-business",
    component: () => import("@/views/business/List.vue"),
    meta: {
      pageTitle: "breadcrumb.business",
      resource: "business",
      action: "read",
      breadcrumb: [
        {
          text: "breadcrumb.listBusiness",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business/create",
    name: "create-business",
    component: () => import("@/views/business/Create.vue"),
    meta: {
      pageTitle: "breadcrumb.business",
      navActiveLink: "list-business",
      resource: "business",
      action: "create",
      breadcrumb: [
        {
          text: "breadcrumb.listBusiness",
          to: { name: "list-business" },
        },
        {
          text: "breadcrumb.createBusiness",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business/:id/update",
    name: "update-business",
    component: () => import("@/views/business/Update.vue"),
    meta: {
      pageTitle: "breadcrumb.business",
      navActiveLink: "list-business",
      resource: "business",
      action: "update",
      breadcrumb: [
        {
          text: "breadcrumb.listBusiness",
          to: { name: "list-business" },
        },
        {
          text: "breadcrumb.updateBusiness",
          active: true,
        },
      ],
    },
  },
  {
    path: "/business/:id/clone",
    name: "clone-business",
    component: () => import("@/views/business/Clone.vue"),
    meta: {
      pageTitle: "breadcrumb.business",
      navActiveLink: "list-business",
      resource: "business",
      action: "clone",
      breadcrumb: [
        {
          text: "breadcrumb.listBusiness",
          to: { name: "list-business" },
        },
        {
          text: "breadcrumb.cloneBusiness",
          active: true,
        },
      ],
    },
  },
];
