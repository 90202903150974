export default [
    {
      path: "/award/update",
      name: "update-award",
      component: () => import("@/views/award/Update.vue"),
      meta: {
        pageTitle: "breadcrumb.award",
        resource: "award",
        action: "update",
        breadcrumb: [
          {
            text: "breadcrumb.updateAward",
            active: true,
          },
        ],
      },
    },
  ];
  